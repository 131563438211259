export default {
  DefaultActiveGoodsId: (state) => state.goods.DefaultActiveGoodsId,
  activeName: (state) => state.personal.activeName,
  token: (state) => state.sys.token,
  userinfo: (state) => state.sys.userinfo,
  inruiryActiveName: (state) => state.personal.inruiryActiveName,
  refreshSelectSku: (state) => state.personal.refreshSelectSku,
  RefreshSelectSkuDelshoppingCarId: (state) =>
    state.personal.RefreshSelectSkuDelshoppingCarId,
  ShoppingCarDatalength: (state) => state.personal.ShoppingCarDatalength,
  InquiryListLength: (state) => state.personal.InquiryListLength,
  AnyRead: (state) => state.personal.AnyRead,
  language: (state) => state.local.language
}
