import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "mt-1"
};
const _hoisted_2 = {
  class: "bg-main mx-auto rounded-sm text-[12px] text-white font-medium text-center w-[16px] h-[14px]"
};
const _hoisted_3 = {
  class: "text-white leading-[14px] shopcar-icon"
};
const _hoisted_4 = {
  class: "bg-main mx-auto rounded-sm text-[12px] text-white font-medium text-center w-[16px] h-[14px]"
};
const _hoisted_5 = {
  class: "text-white leading-[14px] inquiry-icon"
};
import { defineComponent, reactive, ref, computed, onMounted } from 'vue';
import { seachShoppingCar } from '@/api/goods';
import { findCustomerInquiry } from '@/api/inquiry';
import { useStore } from 'vuex';
import router from '@/router';
import { useRoute } from 'vue-router';
import i18n from '@/i18n'; // 要在js中使用国际化

export default {
  __name: 'index',
  setup(__props) {
    const {
      t
    } = i18n.global;
    const route = useRoute();
    const store = useStore();
    const ShoppingCarHandler = async () => {
      const res = await seachShoppingCar(store.getters.userinfo.customerId);
      store.commit('personal/modifyShoppingCarDatalength', res.length);
      const res2 = await findCustomerInquiry(store.getters.userinfo.customerId);
      store.commit('personal/modifyInquiryListLength', res2.length);
    };
    onMounted(() => {
      if (route.path !== '/login' && route.path !== '/register') {
        ShoppingCarHandler();
      }
    });
    const pushShopingCarHandler = () => {
      router.push('/personal');
      store.commit('personal/modifyactiveName', 1);
    };
    const pushInquiryListHandler = () => {
      router.push('/personal');
      store.commit('personal/modifyactiveName', 2);
    };
    return (_ctx, _cache) => {
      return _unref(route).path !== '/login' && _unref(route).path !== '/register' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "px-[4px] shadow h-5 rounded-sm bg-white mb-[4px] pt-[9px] cursor-pointer",
        onClick: pushShopingCarHandler
      }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_unref(store).getters.ShoppingCarDatalength), 1)]), _createElementVNode("p", {
        class: _normalizeClass(["text-text font-medium opacity-50 mt-[7px]", {
          'text-[10px]': _unref(store).getters.language === 'en'
        }])
      }, _toDisplayString(_ctx.$t('personal.ShoppingCart')), 3)]), _createElementVNode("div", {
        class: "px-[4px] shadow h-5 rounded-sm bg-white pt-[9px] cursor-pointer",
        onClick: pushInquiryListHandler
      }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(_unref(store).getters.InquiryListLength), 1)]), _createElementVNode("p", {
        class: _normalizeClass(["text-text font-medium opacity-50 mt-[7px]", {
          'text-[10px]': _unref(store).getters.language === 'en'
        }])
      }, _toDisplayString(_ctx.$t('personal.inquirtyIng')), 3)])])) : _createCommentVNode("", true);
    };
  }
};