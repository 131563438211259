// 中文语言包
export default {
  netName: '皓阳商城',
  // 新增
  Commodities: '全部商品',
  nogoods: '没有商品哦！',
  empty: '暂无',
  uploadTip1: '上传文件需要的token已失效，请您重新登录',
  uploadTip2: '文件大小不能超过',
  uploadTip3: '图片必须是jpg/png/gif格式!',
  requesterrorTip1: '请求出错',
  requesterrorTip2: 'token验证失败',
  requesterrorTip3: '请求服务器出错',
  goodsTip1: '当前商品已下架！！您可以点击左侧商品分类，重新选择上架商品。',
  goodsTip2: '请完整选择商品规格！！！',
  goodsTip3: '询价成功!',
  goodsTip4: '请重新选择产品规格',
  goodsTip5: '取消重新选择',
  loginTips1: '忘记密码',
  loginTips2: '必须输入正确的手机号',
  loginTips3: '登录密码必须是数字和字母的组合',
  loginTips4: '登录账号不可以为空',
  loginTips5: '登录密码不可以为空',
  loginTips6: '密码必须是6-11位字符',
  loginTips7: '验证码不可以为空',
  loginTips8: '请输入正确的登录信息！！！',
  loginTips9: '短信登录',
  loginTips10: '短信验证码',
  loginTips11: '获取',
  loginTips12: '短信验证码不可以为空',
  loginTips13: '请输入验证码',
  loginTips14: '手机区号不可以为空!!',
  loginTips15: '验证码已发送,请您查收!!',
  loginTips16: '返回登录',
  loginTips17: '两次密码输入必须一致',
  loginTips18: '密码修改成功',
  loginTips19: '密码登录',
  loginTips20: '邮箱登录',
  loginTips21: '邮箱验证码',
  loginTips22: '请输入正确的邮箱地址',
  loginTips23: '邮箱验证码不可以为空',
  registerTip1: '欢迎注册',
  registerTip2: '注册',
  registerTip3: '客户姓名',
  registerTip4: '区号',
  registerTip5: '注册密码必须是数字和字母的组合',
  registerTip6: '两次密码输入必须一致！！！',
  registerTip7: '企业名称不可以为空',
  registerTip8: '注册账号不可以为空',
  registerTip9: '注册密码不可以为空',
  registerTip10: '密码不可以为空',
  registerTip11: '客户姓名不可以为空',
  registerTip12: '请输入正确的邮箱',
  registerTip13: '注册成功,请登录!!',
  registerTip14: '请输入正确的注册信息!',
  quotationRemark: '报价备注',
  inquiryTip1: '当前没有询价哦～',
  inquiryTip2: '取消询价成功！！！',
  orderTip1: '当前没有订单，快去下单吧～',
  orderTip2: '确定取消该订单吗?',
  orderTip3: '订单已取消',
  orderTip4: '订单仍然保留。',
  orderTip5: '等待开始服务',
  shopcarTip1: '该商品已经失效',
  shopcarTip2: '购物车没有商品哦～',
  shopcarTip3: '您还没有选中需要删除的商品！!',
  shopcarTip4: '购物车更新成功！！',
  shopcarTip5: '失效商品已清空！！',
  shopcarTip6: '当前购物车没有失效商品！！',
  shopcarTip7: '下单商品不可以为空！！',
  shopcarTip8: '下单成功',
  shopcarTip9: '取消下单',
  baseinfoTip1: '未认证',
  userinfoTip1: '公司全称不可以为空！！',
  userinfoTip2: '企业认证成功',
  userinfoTip3: '资料更新成功！！',
  userinfoTip4: '请正确输入内容！！',
  userinfoTip5: '密码修改成功！！请重新登录',
  userinfoTip6: '请正确输入密码！！',
  userinfoTip7: '收货信息更新成功！！',
  AllgoodsTip1: '周',
  AllgoodsTip2: '数量',
  AllgoodsTip3: '价格',
  AllgoodsTip4: '重新选择完成',
  Loading: '正在加载...',
  //
  back: '返回',
  officialAccount: '公众号',
  telConsult: '电话咨询',
  footerInfo: 'COPYTRIGHT 杭州皓阳生物技术有限公司',
  email: '邮箱',
  login: {
    login: '登录',
    logout: '退出登录',
    phoneNumber: '手机号码',
    emailAddress: '邮箱地址',
    pw: '密码',
    pwAgain: '再次输入密码',
    VerifiCode: '验证码',
    welcomeLogin: '欢迎登录',
    title: '皓阳生物在线商城',
    accountYet: '还没有帐号？',
    toCreate: '去创建',
    ForgotPw: '忘记密码？',
    EnterpriseName: '企业名称',
    ExistingAccount: '已有账号？',
    SignInNow: '立即登录',
    ReSign: '请重新登录',
    currentlogout: '已退出登录！！'
  },
  allgoods: {
    title1: '皓阳服务&商品在线下单',
    title2: '标准产品可直接添加购物车并下单',
    title3:
      '定制服务添加询价后可以在个人中心-我的询价中看到询价结果，如需下单请联系商务人员可帮助您快速下单',
    hotline: '免费咨询热线',
    makeInquiry: '我要询价',
    selectParameters: '请选择参数',
    InquiryRemarks: '询价备注',
    InputInquiryRemarks: '请输入询价备注',
    cancle: '取消',
    canfirm: '确定',
    selectProduct: '请选择产品',
    price: '价格',
    ChinaMonetaryUnit: '元',
    AddToShoppingCart: '添加至购物车',
    noZoro: '规格选项的数量不能都为0！！！',
    cartTips1: '重新选择规格成功，购物车已更新！！',
    cartTips2: '加入购物车成功！'
  },
  helpcenter: {
    title: '帮助中心'
  },
  personal: {
    title: '个人中心',
    ChinaMonetaryUnit: '元',
    cancle: '取消',
    confirm: '确定',
    // 我的资料
    MyProfile: '我的资料',
    // 企业认证弹窗
    certification: '企业认证',
    CertificationStatus: '认证状态',
    license: '营业执照',
    Agreement: '保密协议',
    show: '查看',
    upload: '上传',
    // 修改资料弹窗
    changeInfo: '修改资料',
    EnterpriseLogo: '企业logo',
    companyFullName: '公司全称',
    companyAddress: '公司地址',
    companyTel: '公司电话',

    // 修改密码弹窗
    changePw: '修改密码',
    oldPw: '原密码',
    newPw: '新密码',

    // 基本资料
    baseInfo: '基本资料',
    Certified: '已认证',
    certificationing: '认证中',
    BusinessAddress: '企业地址',

    RegistrationTime: '注册时间',
    CertificationTime: '认证时间',

    // 默认收货信息
    DefaultReceiptInformation: '默认收货信息',
    ModifyReceiptInformation: '修改收货信息',
    ShippingAddress: '收货地址',
    contacts: '联系人',
    contactNumber: '联系电话',
    emailAddress: '邮件地址',

    // 购物车------------------------
    ShoppingCart: '购物车',
    ShoppingCartTitle: '购物车里仅可放入现货类产品，定制服务请选择询价',
    clearInvalidGoods: '清空失效商品',
    BatchDeletion: '批量删除',
    ShoppingCartDialogConfirm: '确定要删除选中的购物车商品吗？',
    placeAnOrder: '下单',
    placeAnOrderConfirm: '是否确认下单？',
    ShoppingCartCount: '合计',
    selectsku: '重选规格',

    // 我的询价------------------------
    MyInquiry: '我的询价',
    inquirtyTitle: '已下单的询价信息不会在这里展示',
    All: '全部',
    inquirtyIng: '询价中',
    Quoted: '已报价',
    inquirtyTime: '询价时间',
    QuotedTime: '报价时间',
    inquirtyRemark: '询价备注',
    showGoods: '查看商品',
    cancleInquirty: '取消询价',
    waitingQuoteIng: '等待报价中',

    // 定制服务订单
    CustomServiceOrder: '定制服务订单',
    created: '已创建',
    Effective: '已生效',
    production: '已排产',
    servering: '服务中',
    Completed: '已完成',
    createTime: '创建时间',
    containGoods: '包含商品',
    orderWaitingEffective: '订单等待生效',
    orderWaitingproduction: '订单等待排产',
    orderStartServer: '订单开始服务',
    ServiceInProgress: '服务进行中',
    CustomServiceorderHasBeencompleted: '订单已完成，请查收数据和报告',
    orderDetail: '订单详情',
    cancleOrder: '取消订单',
    // 标准商品订单
    StandardCommodityOrder: '标准商品订单',
    Shipped: '已发货',
    received: '已收货',
    orderWaitingreceived: '订单等待收货',
    orderWaitingShipped: '订单等待发货',
    orderHasBeencompleted: '订单已完成',
    // 积分纪录
    IntegralRecord: '积分记录',
    getIntegral: '积分获得',
    IntegralgetType: '积分获取类型',
    IntegralgetTime: '获取时间',
    currentVipgrade: '当前会员等级',
    laeveNextGrade: '距离下一级还差',
    Integral: '分',
    // 消息通知
    MessageNotification: '消息通知',
    allRead: '全部已读'
  },
  // 订单详情
  OrderDetail: {
    CustomServiceOrderDetail: '定制服务订单详情',
    // 订单信息
    orderinfo: '订单信息',
    orderPrice: '订单金额',
    headerPrice: '首批款',
    tailPriec: '尾款',
    orderOriPrice: '订单原价',
    orderSale: '订单折扣',
    orderagreement: '订单合同',
    // 相关时间
    aboutTime: '相关时间',
    orderCreate: '订单创建',
    orderEffective: '订单生效',
    serverStart: '服务开始',
    orderComplete: '订单完成',
    serverWeek: '服务周期',
    // 收货信息

    receivedinfo: '收货信息',
    companyName: '公司名称',
    companyAdress: '公司地址',
    orderRemark: '订单备注',
    //  物流信息
    logisticsInfo: '物流信息',
    // 订单商品
    orderGoods: '订单商品',
    goodsId: '商品id',
    priceInfo: '价格信息',
    goodsReportPrice: '商品报价',
    inquirtyTime: '询价时间',
    reportTime: '报价时间',
    serverData: '服务数据',
    serverReport: '服务报告',
    showGoodsDetail: '查看商品',
    downDoument: '下载文档',
    downZip: '下载此页所有文档',
    StandOrderDatail: '标准商品订单详情'
  }
}
