import store from '@/store'
import http from '@/utils/http'

// 查询商品详情 /goods/admin/findGoodsInfo

export const findGoodsInfo = (goodsId) => {
  return http({
    method: 'get',
    url: '/goods/admin/findGoodsInfo',
    params: { goodsId }
  })
}
// 查询标准商品详情
export const findGoodsInfoStand = (goodsId) => {
  return http({
    method: 'get',
    url: '/goods/admin/findGoodsInfo/standard',
    params: { goodsId }
  })
}
// 计算价格

export const calculatePrice = (calculatePriceQOList) => {
  return http({
    method: 'post',
    url: '/order/admin/calculatePrice',
    data: calculatePriceQOList
  })
}
// 商品分类查询（包括商品）
export const findGoodsTypeAndGoods = () => {
  return http({
    method: 'get',
    url: '/goods/admin/findGoodsTypeAndGoods'
  })
}

// 查询goodsClass
export const findGoodsClass = (goodsId) => {
  return http({
    method: 'get',
    url: '/goods/admin/findGoodsClass',
    params: { goodsId }
  })
}

// 查询购物车

export const seachShoppingCar = (customerId) => {
  return http({
    method: 'get',
    url: '/inquiry/seachShoppingCar',
    params: { customerId }
  })
}

// 清空失效商品
export const clearShoppingCar = (customerId) => {
  return http({
    method: 'get',
    url: '/inquiry/clearShoppingCar',
    params: { customerId }
  })
}

// 批量删除购物车
export const delShoppingCar = (shoppingCarIds) => {
  return http({
    method: 'post',
    url: '/inquiry/delShoppingCar',
    data: shoppingCarIds
  })
}

// 购物车合并下单
export const makeShoppongCarOrder = (shoppingCarOrderQO) => {
  return http({
    method: 'post',
    url: '/inquiry/makeShoppongCarOrder',
    data: shoppingCarOrderQO
  })
}

// 购物车合并下单前计算总价
export const countShoppongCarOrder = (shoppingCarOrderQO) => {
  return http({
    method: 'post',
    url: '/inquiry/countShoppongCarOrder',
    data: shoppingCarOrderQO
  })
}
