import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import getters from './getters'
import goods from './modules/goods'
import personal from './modules/personal'
import sys from './modules/sys'
import local from './modules/local'
export default createStore({
  modules: { sys, goods, personal, local },
  getters,
  plugins: [
    // veux持久化配置
    createPersistedstate({
      key: 'store',
      paths: ['goods', 'sys', 'local']
    })
  ]
})
