import { login, logout, getProfile, smslogin } from '@/api/sys'

import router from '@/router'
import store from '..'

function getCookieByName(name) {
  const cookie = document.cookie
  return cookie.split(`; ${name}=`).pop().split(';').shift()
}
export default {
  namespaced: true,
  state: {
    token: '',
    userinfo: {}
  },
  actions: {
    // 登录的动作
    async loginAction(context, loginForm) {
      if (loginForm.messageCode) {
        await smslogin(loginForm)
        const token = getCookieByName('token').split('=')[1]
        context.commit('SETTOKEN', token)
        await this.dispatch('sys/getProfileAction')
      } else if (loginForm.customerPassword) {
        await login(loginForm)
        const token = getCookieByName('token').split('=')[1]
        context.commit('SETTOKEN', token)
        await this.dispatch('sys/getProfileAction')
      }
    },
    // 获取用户信息的动作
    async getProfileAction(context) {
      const data = await getProfile()
      if (!data.customerAgreement) {
        data.customerAgreement = ''
      }
      if (!data.customerLicense) {
        data.customerLicense = ''
      }
      if (!data.customerAddress) {
        data.customerAddress = ''
      }
      if (!data.customerContactPerson) {
        data.customerContactPerson = ''
      }
      if (!data.customerContactPhone) {
        data.customerContactPhone = ''
      }
      if (!data.customerDeliveryAddress) {
        data.customerDeliveryAddress = ''
      }
      if (!data.customerMailAddress) {
        data.customerMailAddress = ''
      }
      if (!data.customerNameShorthand) {
        data.customerNameShorthand = ''
      }
      if (!data.customerPhone) {
        data.customerPhone = ''
      }
      if (!data.customerPhotoUrl) {
        data.customerPhotoUrl = ''
      }

      context.commit('SETUSERINFO', data)
    },
    // 退出登录的动作
    async loginOut() {
      store.commit('sys/SETUSERINFO', {})
      store.commit('sys/SETTOKEN', '')
      store.commit('goods/modifyDefaultActiveGoodsId', '')
      store.commit('personal/modifyactiveName', 0)
      store.commit('personal/modifyRefreshSelectSku', false)
      store.commit('personal/modifyinruiryActiveName', 'ALL')
      store.commit('personal/modifyRefreshSelectSkuDelshoppingCarId', null)
      await logout()
      router.push('/login')
      window.localStorage.removeItem('store')
    }
  },
  mutations: {
    // 存储修改token
    SETTOKEN(state, token) {
      state.token = token
    },
    // 存储修改用户信息
    SETUSERINFO(state, userinfo) {
      state.userinfo = userinfo
    }
  }
}
