export default {
  namespaced: true,
  state: {
    language: 'zhCn'
  },

  mutations: {
    /* 修改language的方法 */
    modifyLanguage: (state, l) => {
      state.language = l
    }
  }
}
