import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09e19da0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w-full h-full"
};
const _hoisted_2 = {
  class: "fixed top-0 left-0 z-10"
};
const _hoisted_3 = {
  class: "h-full pt-6"
};
const _hoisted_4 = {
  class: "fixed bottom-0 w-full left-0 bg-bg h-[55px] flex justify-center z-10"
};
const _hoisted_5 = {
  class: "border-t border-[#ccc] di flex justify-center items-center"
};
const _hoisted_6 = {
  class: "text-sm leading-[22px] opacity-50"
};
import { ref } from 'vue';
import Header from './Header/index.vue';
import loginFloat from '@/components/loginFloat';
import i18n from '@/i18n'; // 要在js中使用国际化

export default {
  __name: 'index',
  setup(__props) {
    const {
      t
    } = i18n.global;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(loginFloat)), _createElementVNode("div", _hoisted_2, [_createVNode(Header)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)('footerInfo')), 1)])])]);
    };
  }
};