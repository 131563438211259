const MAX_FONTSIZE = 40
export const useRem = function () {
  document.addEventListener('DOMContentLoaded', (e) => {
    const html = document.querySelector('html')
    if (html) {
      html.style.fontSize =
        window.innerWidth / 10 > MAX_FONTSIZE
          ? MAX_FONTSIZE + 'px'
          : window.innerWidth / 10 + 'px'
    }
  })
}
