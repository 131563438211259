import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/allgoods',
    children: [
      {
        path: '/allgoods',
        name: 'Allgoods',
        component: () => import('@/views/Allgoods/index.vue')
      },
      {
        path: '/helpcenter',
        name: 'HelpCenter',
        component: () => import('@/views/HelpCenter/index.vue')
      },
      {
        path: '/personal',
        name: 'Personal',
        component: () => import('@/views/Personal/index.vue')
      },
      {
        path: '/nostandorderdetail/:id',
        name: 'Nostandorderdetail',
        component: () => import('@/views/Nostandorderdetail/index.vue')
      },
      {
        path: '/standorderdetail/:id',
        name: 'Standorderdetail',
        component: () => import('@/views/Standorderdetail/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginAndRegister/Login/index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/LoginAndRegister/Register/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
