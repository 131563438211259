import axios from 'axios'
import qs from 'qs'
import store from '@/store'

import showMessage from '@/components/showMessage/index'
import i18n from '@/i18n' // 要在js中使用国际化
const { t } = i18n.global
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

http.param = (data = {}, contentType = 'application/x-www-form-urlencoded') => {
  return contentType === 'application/x-www-form-urlencoded'
    ? qs.stringify(data)
    : contentType === 'application/json'
    ? JSON.stringify(data)
    : data
}

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const lang = store.getters.language

    if (lang === 'zhCn') {
      config.headers['Accept-Language'] = 'zh-CN'
    } else if (lang === 'en') {
      config.headers['Accept-Language'] = 'en'
    }
    if (store.getters.token) {
      config.headers.Authorization = 'Bearer ' + store.getters.token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    const { status, data: resdata } = response
    if (status === 200) {
      // 大部分接口都不是流数据 内容都在data里
      if (resdata.code) {
        const { success, data, code, message } = resdata
        if (success) {
          return data
        } else {
          showMessage({
            type: 'error',
            message: `${message}`
          })
          return Promise.reject(message)
        }
      } else {
        // 流数据的data没有其他内容 只有流数据 直接return
        return resdata
      }
    } else {
      showMessage({
        type: 'error',
        message: t('requesterrorTip1')
      })
      return Promise.reject(status)
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      store.dispatch('sys/loginOut').then(() => {
        showMessage({
          type: 'error',
          message: `${(t('requesterrorTip2'), error.response.data.message)}`
        })
      })
    } else {
      showMessage({
        type: 'error',
        message: `${(t('requesterrorTip3'), error)}`
      })
    }
    return Promise.reject(error)
  }
)
export default http
