export default {
  namespaced: true,
  state: {
    activeName: 0,
    inruiryActiveName: 'ALL',
    refreshSelectSku: false,
    RefreshSelectSkuDelshoppingCarId: null,
    ShoppingCarDatalength: 0,
    InquiryListLength: 0,
    AnyRead: false
  },
  actions: {
    /* getGoodsType: async function () {
      const res = await findGoodsInfo()
      this.commit('commodity/modifyTreeData', res)
    } */
  },
  mutations: {
    /* 修改个人中心左侧的栏目选中的方法 */
    modifyactiveName: (state, newactiveName) => {
      state.activeName = newactiveName
    },
    /* 修改个人中心里询价列表默认选中的tab的方法 */
    modifyinruiryActiveName: (state, newactiveName) => {
      state.inruiryActiveName = newactiveName
    },
    modifyRefreshSelectSku: (state, b) => {
      state.refreshSelectSku = b
    },
    modifyRefreshSelectSkuDelshoppingCarId: (state, shoppingCarId) => {
      state.RefreshSelectSkuDelshoppingCarId = shoppingCarId
    },
    modifyShoppingCarDatalength: (state, l) => {
      state.ShoppingCarDatalength = l
    },
    modifyInquiryListLength: (state, l) => {
      state.InquiryListLength = l
    },
    modifyAnyRead: (state, b) => {
      state.AnyRead = b
    }
  }
}
