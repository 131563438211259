// \src\i18n\index.ts
import { createI18n } from 'vue-i18n'
// 语言包
import zhCn from './lang/cn'
import en from './lang/en'
import store from '@/store'
const getCurrentLanguage = () => {
  // 设置
  const UAlang = store.getters.language
  const langCode = UAlang.indexOf('zh') !== -1 ? 'zhCn' : 'en'
  return langCode
}

const i18n = createI18n({
  legacy: false,
  locale: getCurrentLanguage() || 'zhCn',
  messages: {
    zhCn,
    en
  }
})

export default i18n
