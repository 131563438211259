// 英文语言包
export default {
  netName: 'HealSun Mall',
  // 新增
  Commodities: 'Commodities',
  nogoods: 'No products!',
  empty: 'empty',
  uploadTip1:
    'The token required for uploading files has expired. Please log in again',
  uploadTip2: 'File size cannot exceed ',
  uploadTip3: 'The image must be in jpg/png/gif format!',
  requesterrorTip1: 'Request error',
  requesterrorTip2: 'Token validation failed',
  requesterrorTip3: 'Request server error',
  goodsTip1:
    'The current product has been removed from the shelf!! You can click on the product category on the left to reselect the items on the shelf.',
  goodsTip2: 'Please select a complete product specification!',
  goodsTip3: 'Inquiry succeeded!',
  goodsTip4: 'Please reselect the product specification',
  goodsTip5: 'Cancel reselection',
  loginTips1: 'Forgot your password?',
  loginTips2: 'You must enter the correct phone number',
  loginTips3: 'Login password must be a combination of numbers and letters',
  loginTips4: 'The login account cannot be empty',
  loginTips5: 'The login password cannot be empty',
  loginTips6: 'Password must be 6-11 characters',
  loginTips7: 'The verification code cannot be empty',
  loginTips8: 'Please enter the correct login information!!!',
  loginTips9: 'Message verification code login',
  loginTips10: 'SMS verification code',
  loginTips11: 'gain',
  loginTips12: 'The SMS verification code cannot be empty',
  loginTips13: 'Please enter the verification code',
  loginTips14: 'Mobile phone area code cannot be empty',
  loginTips15: 'The verification code has been sent, please check it',
  loginTips16: 'Return to login',
  loginTips17: "Two inputs don't match!",
  loginTips18: 'Password modified successfully',
  loginTips19: 'Password Login',
  loginTips20: 'Email Login',
  loginTips21: 'Email verification code',
  loginTips22: 'Please enter the correct email address ',
  loginTips23: 'Email verification code cannot be empty',
  registerTip1: 'Welcome to register',
  registerTip2: 'register',
  registerTip3: 'Customer Name',
  registerTip4: 'Area code',
  registerTip5:
    'The registration password must be a combination of numbers and letters',
  registerTip6: 'The two password entries must be consistent!!!',
  registerTip7: 'Enterprise name cannot be empty',
  registerTip8: 'The registered account cannot be empty',
  registerTip9: 'The registration password cannot be empty',
  registerTip10: 'The password cannot be empty',
  registerTip11: 'Customer name cannot be empty',
  registerTip12: 'Please enter the correct email address',
  registerTip13: 'Registration succeeded, please login!!',
  registerTip14: 'Please enter the correct registration information',
  quotationRemark: 'quotation Remark',
  inquiryTip1: 'Currently, there is no inquiry～',
  inquiryTip2: 'Request for quote has been canceled！',
  orderTip1: 'There is currently no order, please go and place the order～',
  orderTip2: 'Are you sure to cancel this order?',
  orderTip3: 'Order canceled.',
  orderTip4: 'The order remains on hold.',
  orderTip5: 'Waiting to start service',
  shopcarTip1: 'This product has expired',
  shopcarTip2: 'There are no items in the shopping cart',
  shopcarTip3: 'You have not selected the product to delete yet!',
  shopcarTip4: 'Shopping cart updated successfully!!',
  shopcarTip5: 'Invalid products have been cleared!!',
  shopcarTip6: 'There are no invalid items in the current shopping cart!!',
  shopcarTip7: 'Ordered item cannot be empty!!',
  shopcarTip8: 'checkout success！！',
  baseinfoTip1: 'Not certified',
  userinfoTip1: 'The full company name cannot be empty!!',
  userinfoTip2: 'Enterprise certification succeeded',
  userinfoTip3: 'Data updated successfully!!',
  userinfoTip4: 'Please enter the content correctly!!',
  userinfoTip5: 'Password modified successfully!! Please login again',
  userinfoTip6: 'Please enter the password correctly!!',
  AllgoodsTip1: 'week',
  AllgoodsTip2: 'number',
  AllgoodsTip3: 'Price',
  AllgoodsTip4: 'Re select complete',
  Loading: 'Loading...',
  //
  back: 'Back',
  officialAccount: 'Wechat Official Account',
  telConsult: 'Tel Counseling',
  footerInfo: 'COPYTRIGHT Hangzhou HealSun Biopharm Co., Ltd.',
  email: 'E-mail',
  login: {
    login: 'Login',
    logout: 'Logout',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    pw: 'Password',
    pwAgain: 'Password Again',
    VerifiCode: 'Verification Code',
    welcomeLogin: 'Welcome to HealSun Mall',
    title: 'HealSun Biopharm Online Mall',
    accountYet: 'New to HealSun?',
    toCreate: 'Create your HealSun account',
    ForgotPw: 'Forgot password?',
    EnterpriseName: 'Enterprise Name',
    ExistingAccount: 'Already have an account?',
    SignInNow: 'Sign in now',
    ReSign: 'Please log in again',
    currentlogout: 'Logged out!!'
  },
  allgoods: {
    title1: 'HealSun Services & Products Online Order',
    title2: 'Add regular products to cart and shop now.',
    title3:
      'Add customized services for quotation request, and check in My Center-My RFQ. For quick orders, please contact us.',
    hotline: 'Free Hotline',
    makeInquiry: 'Price Inquiry',
    selectParameters: 'Select Parameters',
    InquiryRemarks: 'Inquiry Remarks',
    InputInquiryRemarks: 'Input Inquiry Remarks',
    cancle: 'Cancel',
    canfirm: 'Confirm',
    selectProduct: 'Select Product',
    price: 'Price',
    ChinaMonetaryUnit: 'RMB',
    AddToShoppingCart: 'Add to Cart',
    noZoro: 'The quantity of specification options cannot all be 0!!!',
    cartTips1:
      'Successfully reselected specifications, shopping cart has been updated!!',
    cartTips2: 'Successfully added to shopping cart!'
  },
  helpcenter: {
    title: 'Help Center'
  },
  personal: {
    title: 'My Center',
    ChinaMonetaryUnit: 'RMB',
    cancle: 'Cancel',
    confirm: 'Confirm',
    // 我的资料
    MyProfile: 'My Profile',
    // 企业认证弹窗
    certification: 'Certify your enterprise',
    CertificationStatus: 'Certification Status',
    license: 'Business License',
    Agreement: 'Confidentiality Agreement',
    show: 'View',
    upload: 'Upload',
    // 修改资料弹窗
    changeInfo: 'Modify your profile',
    EnterpriseLogo: 'Enterprise Logo',
    companyFullName: 'Company Full Name',
    companyAddress: 'Company Address',
    companyTel: 'Company Tel',

    // 修改密码弹窗
    changePw: 'Change your password',
    oldPw: 'Password',
    newPw: 'New Password',

    // 基本资料
    baseInfo: 'Basic Information',
    Certified: 'Certified',
    certificationing: 'Certifying',
    BusinessAddress: 'Enterprise Address',

    RegistrationTime: 'Registration Time',
    CertificationTime: 'Certification Time',

    // 默认收货信息
    DefaultReceiptInformation: 'Default Delivery Information',
    ModifyReceiptInformation: 'Modify Delivery Information',
    ShippingAddress: 'Delivery Address',
    contacts: 'Contact',
    contactNumber: 'Phone Number',
    emailAddress: 'E-mail',

    // 购物车------------------------
    ShoppingCart: 'Shopping Cart',
    ShoppingCartTitle:
      'Cart is only for in-stock products. For customized services, please select inquiry.',
    clearInvalidGoods: 'Clear Invalid Goods',
    BatchDeletion: 'Bulk Deletion',
    ShoppingCartDialogConfirm: 'Confirm to delete selected items?',
    placeAnOrder: 'Place an Order',
    placeAnOrderConfirm: 'Confirm to order?',
    ShoppingCartCount: 'In Total',
    selectsku: 'Reselect Specifications',

    // 我的询价------------------------
    MyInquiry: 'My Inquiry',
    inquirtyTitle: 'Inquiries for the purchased are not',
    All: 'All',
    inquirtyIng: 'Under Inquiry',
    Quoted: 'Quoted',
    inquirtyTime: 'Inquiry Time',
    QuotedTime: 'Quoted Time',
    inquirtyRemark: 'Inquiry Remarks',
    showGoods: 'Show Product Detail',
    cancleInquirty: 'Cancel Inquiry',
    waitingQuoteIng: 'Waiting for Inquiry',

    // 定制服务订单
    CustomServiceOrder: 'Customized Services Order',
    created: 'Created',
    Effective: 'Effective',
    production: 'Waiting for Production',
    servering: 'Under Service',
    Completed: 'Completed',
    createTime: 'Create Time',
    containGoods: 'Products Contained',
    orderWaitingEffective: 'Order ready for effectiveness',
    orderWaitingproduction: 'Order ready for production',
    orderStartServer: 'Start of Service',
    ServiceInProgress: 'Under Service',
    CustomServiceorderHasBeencompleted:
      'Your order is completed, please check the data and report.',
    orderDetail: 'Order Detail',
    cancleOrder: 'Cancel Order',
    // 标准商品订单
    StandardCommodityOrder: 'Regular Product Orders',
    Shipped: 'In Delivery',
    received: 'Received',
    orderWaitingreceived: 'Orders to be received',
    orderWaitingShipped: 'Orders to be delivered',
    orderHasBeencompleted: 'Order Completed',
    // 积分纪录
    IntegralRecord: 'Points Record',
    getIntegral: 'Get Points',
    IntegralgetType: 'Point Types',
    IntegralgetTime: 'Pointed Time',
    currentVipgrade: 'Current VIP Grade',
    laeveNextGrade: 'For next grade, ',
    Integral: 'points left',
    // 消息通知
    MessageNotification: 'Message Notification',
    allRead: 'All Read'
  },
  // 订单详情
  OrderDetail: {
    CustomServiceOrderDetail: 'Customized Order Detail',
    // 订单信息
    orderinfo: 'Order Information',
    orderPrice: 'Order Price',
    headerPrice: 'Initial Payment',
    tailPriec: 'Balance Payment',
    orderOriPrice: 'Original Price',
    orderSale: 'Discount',
    orderagreement: 'Order Contract',
    // 相关时间
    aboutTime: 'About Time',
    orderCreate: 'Order Created',
    orderEffective: 'Order Effective',
    serverStart: 'Start of Service',
    orderComplete: 'Order Completed',
    serverWeek: 'Service Cycle',
    // 收货信息

    receivedinfo: 'Delivery Information',
    companyName: 'Company Name',
    companyAdress: 'Company Address',
    orderRemark: 'Order Remarks',
    //  物流信息
    logisticsInfo: 'Logistics Information',
    // 订单商品
    orderGoods: 'Products Ordered',
    goodsId: 'Product ID',
    priceInfo: 'Price',
    goodsReportPrice: 'Product Inquiry',
    inquirtyTime: 'Inquiry Time',
    reportTime: 'Quoted Time',
    serverData: 'Service Data',
    serverReport: 'Service Report',
    showGoodsDetail: 'Show Product Detail',
    downDoument: 'Download Documents',
    downZip: 'Download all documents of this page.',
    StandOrderDatail: 'Regular Product Order Detail'
  }
}
