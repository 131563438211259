import store from '@/store'
import http from '@/utils/http'

// 询价合并下单前计算总价
export const countInquiryOrder = (inquiryOrderQO) => {
  return http({
    method: 'post',
    url: '/inquiry/countInquiryOrder',
    data: inquiryOrderQO
  })
}

// 询价合并下单
export const makeInquiryOrder = (inquiryOrderQO) => {
  return http({
    method: 'post',
    url: '/inquiry/makeInquiryOrder',
    data: inquiryOrderQO
  })
}

// 查询客户询价单

export const findCustomerInquiry = (customerId) => {
  return http({
    method: 'post',
    url: '/inquiry/findCustomerInquiry',
    params: {
      customerId
    }
  })
}

// 客户询价
export const customerInquiryPrice = (inquiryQO) => {
  return http({
    method: 'post',
    url: '/inquiry/customerInquiryPrice',
    data: inquiryQO
  })
}

// 加入购物车
export const addShoppingCar = (shoppingCarQOList) => {
  return http({
    method: 'post',
    url: '/inquiry/addShoppingCar',
    data: shoppingCarQOList
  })
}

// 取消报价
export const deleteInquiry = (inquiryId) => {
  return http({
    method: 'get',
    url: '/inquiry/deleteInquiry',
    params: {
      inquiryId
    }
  })
}
