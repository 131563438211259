// 1. 导入所有的svg图标
// 2. 完成SvgIcon的全局注册

import SvgIcon from '@/components/SvgIcon'

// require.context生成一个require方法 这个方法身上key属性 可以得到一个数组 数组内部的元素是所有的svg图标 然后将每个元素为参数传入require方法中 这样就导入了所有的svg的图标了
const svgRequire = require.context('./svg', false, /\.svg$/)

svgRequire.keys().forEach((svgIcon) => {
  svgRequire(svgIcon)
})

// SvgIcon的全局注册 在去main中导入就可以了
export default (app) => {
  app.component('svg-icon', SvgIcon)
}
