import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "h-screen w-screen"
};
import { computed } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import { useStore } from 'vuex';
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const locale = computed(() => {
      return store.getters.language === 'zhCn' ? zhCn : en;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        locale: locale.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};