import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  "aria-hidden": "true"
};
const _hoisted_2 = ["xlink:href", "fill"];
import { computed } from 'vue';
export default {
  __name: 'SvgIcon',
  props: {
    names: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    fillClass: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    const symbolId = computed(() => {
      return `#icon-${props.names}`;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
        "xlink:href": symbolId.value,
        fill: __props.color,
        class: _normalizeClass(__props.fillClass)
      }, null, 10, _hoisted_2)]);
    };
  }
};