export default {
  namespaced: true,
  state: {
    DefaultActiveGoodsId: ''
  },
  actions: {
    /* getGoodsType: async function () {
      const res = await findGoodsInfo()
      this.commit('commodity/modifyTreeData', res)
    } */
  },
  mutations: {
    /* 修改DefaultActiveGoodsId的方法 */
    modifyDefaultActiveGoodsId: (state, id) => {
      state.DefaultActiveGoodsId = id
    }
  }
}
