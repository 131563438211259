import { createApp } from 'vue'

import App from './App.vue'
import '@/styles/tainwindcss/index.css'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import '@/styles/element/index.scss'
// 全局导入svgIcon这个组件
import installIcons from '@/icons'
import { useRem } from '@/utils/flexible'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from '@/i18n/index'
import ElementPlus from 'element-plus'

useRem()
const app = createApp(App)

installIcons(app)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router).use(store).use(i18n).use(ElementPlus).mount('#app')
