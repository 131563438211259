import store from '@/store'
import http from '@/utils/http'
import { Encrypt } from '@/utils/secret'
// 登录获取验证码的接口
export const getKaptcha = () => {
  return http({
    method: 'get',
    url: '/common/mall/kaptcha',
    responseType: 'arraybuffer'
  })
}

// 登录获取token的接口
export const login = (loginForm) => {
  const { customerLoginId, customerPassword, verifyCode, customerAreaCode } =
    loginForm
  const md5Password = Encrypt(customerPassword)
  window.localStorage.removeItem('store')
  return http({
    method: 'post',
    url: '/customer/login',
    data: http.param({
      customerLoginId,
      customerPassword: md5Password,
      verifyCode,
      customerAreaCode
    })
  })
}
// 短信登录
export const smslogin = (loginForm) => {
  const { customerAreaCode, customerLoginId, messageCode } = loginForm
  return http({
    method: 'post',
    url: '/customer/loginByMessage',
    data: http.param({
      customerAreaCode,
      customerLoginId,
      messageCode
    })
  })
}
// 获取短信验证码
export const getSmsCode = (params) => {
  return http({
    method: 'get',
    url: '/common/sendMessage',
    params
  })
}
// 忘记密码（客户端）
export const losePassword = (form) => {
  const {
    customerAreaCode,
    customerLoginId,
    messageCode,
    newCustomerPassword
  } = form
  return http({
    method: 'post',
    url: '/customer/losePassword',
    data: http.param({
      customerAreaCode,
      customerLoginId,
      messageCode,
      newCustomerPassword
    })
  })
}
// 登录后获取用户信息的接口
export const getProfile = () => {
  return http({
    method: 'get',
    url: '/customer/seachCustomer'
  })
}

// 退出登录的接口
export const logout = () => {
  return http({
    method: 'get',
    url: '/customer/logout'
  })
}

//  企业认证
export const customerApprove = (
  customerAgreement,
  customerCertificationStatus,
  customerId,
  customerLicense
) => {
  return http({
    method: 'post',
    url: '/customer/customerApprove',
    params: {
      customerAgreement,
      customerCertificationStatus,
      customerId,
      customerLicense
    }
  })
}

// 修改客户 --企业信息
export const editCustomerInfo = (CustomerInfo) => {
  return http({
    method: 'post',
    url: '/customer/editCustomerInfo',
    params: CustomerInfo
  })
}
// 企业注册
export const signCustomer = (obj) => {
  return http({
    method: 'post',
    url: '/customer/signCustomer',
    params: obj
  })
}

// 查询会员和积分设置
export const seachIntegralConfig = () => {
  return http({
    method: 'get',
    url: '/customer/seachIntegralConfig'
  })
}

// 查询客户积分记录
export const seachCustomerIntegralLog = (customerId) => {
  return http({
    method: 'get',
    url: '/customer/seachCustomerIntegralLog',
    params: {
      customerId
    }
  })
}

// 修改密码（客户端）
export const editPassword = (customerId, newPassword, oldPassword) => {
  return http({
    method: 'post',
    url: '/customer/editPassword',
    params: {
      customerId,
      newPassword,
      oldPassword
    }
  })
}

// 查询消息分页面 /messageCenter/findMessagePage
export const findMessagePage = (seachMessageQO) => {
  return http({
    method: 'post',
    url: '/messageCenter/findMessagePage',
    data: seachMessageQO
  })
}

// 查询客户是否有未读消息
export const messageAnyRead = (customerId) => {
  return http({
    method: 'get',
    url: '/messageCenter/messageAnyRead',
    params: {
      customerId
    }
  })
}

// 全部已读
export const messageAllRead = (customerId) => {
  return http({
    method: 'get',
    url: '/messageCenter/messageAllRead',
    params: {
      customerId
    }
  })
}
// 获取客户端登陆设置
export const getCustSysConfig = () => {
  return http({
    method: 'get',
    url: '/helpCenter/getCustSysConfigWithoutLogin'
  })
}
